.header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	background-color: var(--white);
	border-bottom: 1px solid var(--gray);
	transition:
		background-color 0.5s ease,
		box-shadow 0.5s ease,
		max-width 0.3s ease,
		transform 0.3s;

	&-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
		width: var(--containerMaxWidth);
		max-width: 100%;
		margin: 0 auto;
	}

	&-svgLogo {
		display: none;
	}
}

@media (--media-tablet) {
	.header {
		&-wrapper {
			padding: 0 10px;
		}
	}
}

@media (--media-desktop) {
	.header {
		position: sticky;
		top: 0;
		height: 80px;

		&.scrolling {
			box-shadow: 0 2px 40px rgb(15 15 15 / 5%);
		}

		&-svgLogo {
			display: block;
		}
	}

	.isHome {
		.header {
			opacity: 0;
		}
	}
}
