.cta {
	font-family: var(--circular);
	font-weight: 200;
	font-size: inherit;
	color: var(--red);
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 1px;
	position: relative;
	background-color: transparent;
	border: 1px solid var(--red);
	outline: none;
	padding: 8px 20px;
	border-radius: 5px;

	&.disabled {
		cursor: not-allowed;
	}

	&-content {
		visibility: visible;
		opacity: 1;
		transition:
			visibility 0.3s,
			opacity 0.3s;
	}

	&-loader {
		display: none;
		position: absolute;
		inset: 0;
		place-items: center;
		visibility: hidden;
		opacity: 0;
		transition:
			visibility 0.3s,
			opacity 0.3s;
	}

	&.loading {
		pointer-events: none;

		.cta-loader {
			display: grid;
			opacity: 1;
			visibility: visible;
		}

		.cta-content {
			visibility: hidden;
			opacity: 0;
		}
	}
}
