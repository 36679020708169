[title='recaptcha challenge'] {
	position: fixed;
	top: 0;
}

/* Lenis recommended css */
html.lenis {
	height: auto;
}

.lenis.lenis-smooth {
	scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
	overscroll-behavior: contain;
}

.lenis.lenis-stopped {
	overflow: hidden;
}

.lenis.lenis-scrolling iframe {
	pointer-events: none;
}

/* ///////////////////////////////////////////////////////////////////// */
* {
	box-sizing: border-box;
	margin-left: 0;
	margin-right: 0;
	-webkit-overflow-scrolling: touch;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

body {
	position: relative;
	font-family: var(--circular);
	min-height: 100vh;
	color: var(--black);
	font-weight: 200;

	&.overflow-y {
		overflow-y: hidden;
	}
}

h1,
h2 {
	margin: 0;
}

img {
	max-width: 100%;
}

a {
	text-decoration: none;
}

/* stylelint-disable */
.sr-only {
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	width: 1px;
	height: 1px;
	position: absolute;
	white-space: nowrap;
	border-width: 0px;
	border-style: initial;
	border-color: initial;
	border-image: initial;
	margin: -1px;
	padding: 0px;
	overflow: hidden;
}
/* stylelint-enable */

@media (--media-desktop) {
	input,
	textarea,
	button,
	a {
		&:focus-visible {
			border-color: var(--focusBorderColor);
			outline: 0;
			box-shadow: var(--boxShadowFocusColor);
		}
	}
}
