@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.loader {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: inline-block;
	border-top: 3px solid var(--blue);
	border-right: 3px solid transparent;
	animation: rotation 1s linear infinite;
}
