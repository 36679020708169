/* cirular font book - 200 */
@font-face {
	font-family: circular-std;
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src:
		local(''),
		url('../fonts/CircularStd-Book.woff2') format('woff2');
}

/* cirular font medium - 400 */
@font-face {
	font-family: circular-std;
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		local(''),
		url('../fonts/CircularStd-Medium.woff2') format('woff2');
}

/* cirular font book - 600 */
@font-face {
	font-family: circular-std;
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src:
		local(''),
		url('../fonts/CircularStd-Bold.woff2') format('woff2');
}
