.mainTitle {
	width: 100%;
	display: flex;
	justify-content: center;

	&-svg {
		width: 78px;
		height: 30px;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&-svgLink {
		display: block;
		height: 100%;
	}
}

@media (--media-desktop) {
	.mainTitle {
		&-svg {
			width: 115px;
			height: 37px;
		}
	}
}
