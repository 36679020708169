.navbar {
	.submenu-btn {
		font-family: var(--circular);
	}
}

@media (--media-navbar) {
	.navbar {
		@keyframes fadeInBottom {
			0% {
				transform: translateY(10px);
				opacity: 0;
			}

			100% {
				transform: translateY(0);
				opacity: 1;
			}
		}

		@keyframes fadeOutBottom {
			0% {
				transform: translateY(0);
				opacity: 1;
			}

			100% {
				transform: translateY(10px);
				opacity: 0;
			}
		}

		position: fixed;
		inset: 0;
		padding: 40px 18px 30px;
		background-color: var(--gray);
		transition: all 0.55s ease-out 0.55s;
		opacity: 0;
		pointer-events: none;

		&-listItemLink,
		.submenu-listItemLink {
			display: inline-block;
			color: var(--black);
			text-transform: capitalize;
			font-size: 24px;
			line-height: 24px;
			font-weight: 400;
		}

		&-listItem {
			text-align: center;

			&.active {
				pointer-events: none;

				.navbar-listItemLink {
					color: var(--blue);
				}
			}

			/* animation */
			&:nth-child(1) {
				animation: 0.2s ease-out 0.35s 1 normal both running fadeOutBottom;
			}

			&:nth-child(2) {
				animation: 0.2s ease-out 0.3s 1 normal both running fadeOutBottom;
			}

			&:nth-child(3) {
				animation: 0.2s ease-out 0.25s 1 normal both running fadeOutBottom;
			}

			&:nth-child(4) {
				animation: 0.2s ease-out 0.2s 1 normal both running fadeOutBottom;
			}

			&:nth-child(5) {
				animation: 0.2s ease-out 0.15s 1 normal both running fadeOutBottom;
			}

			&:nth-child(6) {
				animation: 0.2s ease-out 0.1s 1 normal both running fadeOutBottom;
			}

			&:nth-child(7) {
				animation: 0.2s ease-out 0.05s 1 normal both running fadeOutBottom;
			}
		}

		&-logo {
			width: 195px;
			height: 30px;
			margin: 0 auto 85px;
			transform: translateY(-20px);
			opacity: 0;
			transition:
				transform 0.25s ease-out 0.3s,
				opacity 0.3s ease-out 0.3s;

			svg {
				width: 100%;
				height: 100%;
			}
		}

		&.active {
			transition: all 0.3s ease-out 0s;
			opacity: 1;
			pointer-events: all;

			.navbar-logo {
				transform: translateX(0);
				opacity: 1;
			}

			.navbar-listItem {
				&:nth-child(1) {
					animation: 0.2s ease-out 0.11s 1 normal both running fadeInBottom;
				}

				&:nth-child(2) {
					animation: 0.2s ease-out 0.14s 1 normal both running fadeInBottom;
				}

				&:nth-child(3) {
					animation: 0.2s ease-out 0.17s 1 normal both running fadeInBottom;
				}

				&:nth-child(4) {
					animation: 0.2s ease-out 0.2s 1 normal both running fadeInBottom;
				}

				&:nth-child(5) {
					animation: 0.2s ease-out 0.23s 1 normal both running fadeInBottom;
				}

				&:nth-child(6) {
					animation: 0.2s ease-out 0.26s 1 normal both running fadeInBottom;
				}

				&:nth-child(7) {
					animation: 0.2s ease-out 0.26s 1 normal both running fadeInBottom;
				}
			}
		}

		&-list {
			display: flex;
			flex-direction: column;
			gap: 50px;
			line-height: 15px;
		}

		.submenu-list {
			position: relative;
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.3s;
			padding: 0 15px;
			margin-left: 10px;
			border-left: 6px solid var(--black);
		}

		.button {
			position: absolute;
			width: 15px;
			height: 15px;
			right: -23px;
			top: 50%;
			transform: translateY(-50%) scale(0.7);
			pointer-events: none;

			&::before,
			&::after {
				content: '';
				position: absolute;
				background-color: var(--black);
				transition: transform 0.25s ease-out;
			}

			/* Vertical line */
			&::before {
				top: 0;
				left: 50%;
				width: 2px;
				height: 100%;
				margin-left: -1px;
			}

			/* horizontal line */
			&::after {
				top: 50%;
				left: 0;
				width: 100%;
				height: 2px;
				margin-top: -1px;
			}
		}

		.submenu-btn {
			background-color: transparent;
			border: none;
			padding: 15px 10px 15px 0;
			text-transform: uppercase;
			font-size: 14px;
			color: var(--black);
			width: 100%;
			text-align: left;

			&.opened {
				.submenu-btnText::after {
					transform: rotate(225deg);
				}

				.button {
					cursor: pointer;

					&::before {
						transform: rotate(90deg);
					}

					&::after {
						transform: rotate(180deg);
					}
				}
			}
		}

		.submenu-btnText {
			position: relative;
			display: inline-block;
			pointer-events: none;
			font-weight: 600;
		}

		.slideBar {
			display: none;
		}
	}
}

@media (--media-desktop) {
	.navbar {
		max-width: var(--containerMaxWidth);
		margin: 0 auto;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		position: relative;

		&-logo {
			display: none;
		}

		&-list {
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		.submenu-listItemLink {
			display: block;
			white-space: nowrap;
			padding: 15px 5px;
			text-transform: uppercase;
			font-size: 10px;
			letter-spacing: 1px;
			color: var(--black);
			transition: color 0.3s;
			font-weight: 600;
		}

		&-listItem {
			transition: color 0.3s;
			position: relative;
			z-index: 1;

			&.active {
				pointer-events: none;
			}

			.submenu-listItem:hover {
				.submenu-listItemLink {
					color: var(--red);
				}
			}
		}

		&-listItemLink {
			display: block;
			line-height: 20px;
			padding: 10px 30px;
			font-size: 14px;
			transition: color 0.3s;
			color: var(--black);
			font-weight: 600;
		}

		.submenu-list {
			position: absolute;
			opacity: 0;
			visibility: hidden;
			transition:
				opacity 0.3s,
				visibility 0.3s,
				transform 0.3s;
			box-shadow: 0 8px 16px rgb(0 0 0 / 15%);
			background-color: var(--white);
			padding: 10px;
			transform: translateY(10px);
			pointer-events: none;
		}

		.submenu-btnTextItem {
			color: var(--black);
			transition: color 0.3s;
			line-height: 10px;
		}

		&-listItem.dropdown {
			&:hover {
				.submenu-list {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
					pointer-events: auto;
				}

				.submenu-btnTextItem {
					color: var(--red);
				}
			}
		}

		.submenu-btn {
			padding: 15px 5px;
			background-color: transparent;
			border: none;
			text-align: left;
			line-height: 16px;
			text-transform: uppercase;
			font-size: 10px;
			letter-spacing: 1px;
			font-weight: 600;
		}

		.submenu-btnTextItemCaret {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 2px;
			vertical-align: middle;
			border-top: 4px solid;
			border-right: 4px solid transparent;
			border-left: 4px solid transparent;
		}

		.submenu-btnText {
			display: flex;
		}

		.button,
		.socials,
		.copyright {
			display: none;
		}

		&-slideBar {
			height: 2px;
			background-color: var(--black);
			position: absolute;
			width: 45px;
			bottom: 0;
			transform: translateX(-50%);
			opacity: 0;
			transition:
				opacity 0.3s,
				left 0.3s var(--cubicBezier),
				width 0.3s var(--cubicBezier);

			&.active {
				opacity: 1;
			}
		}
	}
}
