.footer {
	background-color: var(--white);
	font-size: 12px;
	border-top: 1px solid var(--gray);
	padding: 15px 0 34px;

	&-wrapper {
		width: var(--containerMaxWidth);
		max-width: 100%;
		margin: 0 auto;
	}

	&-svg {
		width: 63px;
		height: 68px;
		margin: 0 auto 10px;

		svg {
			width: 100%;
			height: 100%;
		}
	}

	&-list {
		display: grid;
		padding: 0 50px;
		text-align: center;
		row-gap: 15px;
		grid-template-areas:
			'a b c'
			'd d d'
			'e e e';
		margin-bottom: 30px;
	}

	&-listItemLink {
		color: inherit;
		font-size: 14px;
		line-height: 16px;
	}

	&-listItem {
		&:nth-child(7),
		&:nth-child(8) {
			color: var(--darkestGray);
			grid-area: d;
			margin-top: 20px;

			.footer-listItemLink {
				font-size: 12px;
			}
		}

		&:nth-child(5) {
			grid-area: b;
		}

		&:nth-child(8) {
			grid-area: e;
			margin-top: 0;
		}
	}

	.subitem {
		display: none;
	}

	&-copyright {
		text-align: center;
		margin-bottom: 15px;
	}

	&-webmaster {
		text-align: center;
	}

	&-webmasterLink {
		color: var(--blue);
		font-weight: 500;
	}
}

@media (--media-desktop) {
	.footer {
		padding: 55px 0 70px;

		&-wrapper {
			display: grid;
			grid-template-columns: minmax(0, max-content) 1fr;
			grid-template-rows: minmax(0, max-content) 1fr;
			gap: 20px 100px;
		}

		&-svg {
			width: 100%;
			height: 100%;
			margin: 0;

			svg {
				width: 130px;
				height: 140px;
			}
		}

		&-list {
			max-width: var(--containerMaxWidth);
			margin: 0 auto;
			gap: 0 116px;
			padding: 0;
			grid-template-areas:
				'projets apropos mentions'
				'digital contact protection'
				'print print print'
				'illustration illustration illustration';
			grid-row: 1/3;
			grid-column: 2/3;
			align-items: self-end;
		}

		&-listItem {
			font-size: 14px;
			text-align: left;

			.footer-listItemLink {
				font-size: inherit;
			}

			&:nth-child(1) {
				grid-area: projets;
			}

			&:nth-child(2) {
				grid-area: digital;
				color: var(--darkestGray);

				.footer-listItemLink {
					font-size: 14px;
				}
			}

			&:nth-child(3) {
				grid-area: print;
				color: var(--darkestGray);

				.footer-listItemLink {
					font-size: 14px;
				}
			}

			&:nth-child(4) {
				grid-area: illustration;
				color: var(--darkestGray);

				.footer-listItemLink {
					font-size: 14px;
				}
			}

			&:nth-child(5) {
				grid-area: apropos;
			}

			&:nth-child(6) {
				grid-area: contact;
			}

			&:nth-child(7) {
				grid-area: mentions;
				margin-top: 0;
				color: inherit;

				.footer-listItemLink {
					font-size: inherit;
				}
			}

			&:nth-child(8) {
				grid-area: protection;
				color: inherit;

				.footer-listItemLink {
					font-size: inherit;
				}
			}
		}

		.subitem {
			display: block;
		}

		&-copyright {
			font-size: 14px;
			text-align: left;
			margin-bottom: 0;
		}

		&-webmaster {
			text-align: left;
		}
	}
}
