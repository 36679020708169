:root {
	--containerMaxWidth: 1024px;
	--navbarHeight: 70px;

	/* fonts */
	--circular: circular-std, sans serif;

	/* colors */
	--white: #fff;
	--black: #000;
	--red: #e64e4e;
	--gray: #ddd;
	--lightGray: #fafafa;
	--darkGray: #dbdbdb;
	--darkestGray: #585858;
	--blue: #00a;

	/* warning */
	--error: #721c24;
	--backgroundError: #f8d7da;
	--borderError: #f5c6cb;
	--success: #155724;
	--backgroundSuccess: #d4edda;
	--borderSuccess: #c3e6cb;

	/* box-shadow */
	--box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);

	/* focus */
	--focusBorderColor: #80bdff;
	--boxShadowFocusColor: 0 0 0 0.2rem rgb(0 123 255 / 25%);

	/* Transitions */
	--cubicBezier: cubic-bezier(0.83, 0, 0.17, 1);
}

/* Navbar breakpoint */
@custom-media --media-navbar only screen and (max-width: 1023px);

/* Breakpoints */
@custom-media --media-mobile only screen and (max-width: 749px);
@custom-media --media-tablet only screen and (min-width: 750px);
@custom-media --media-desktop only screen and (min-width: 1024px);
@custom-media --media-desktop-large only screen and (min-width: 1200px);
