:root {
	--lineColor: var(--black);
}

.isHome {
	.hamburger {
		opacity: 0;
	}
}

@media (--media-navbar) {
	.hamburger {
		position: absolute;
		top: 15px;
		right: 0;
		width: 70px;
		height: 70px;
		background-color: transparent;
		user-select: none;
		border: none;
		cursor: pointer;
		z-index: 1000;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		&-line {
			width: 100%;
			height: 2px;
			background-color: var(--black);
			position: absolute;
			transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
			border-radius: 10px;

			&:nth-child(1) {
				top: 0;
			}

			&:nth-child(2) {
				top: 50%;
				transform: translateY(-50%);
			}

			&:nth-child(3) {
				bottom: 0;
			}
		}

		&.active {
			.hamburger-line {
				&:nth-child(1) {
					top: 50%;
					transform: translateY(-50%);
				}

				&:nth-child(2) {
					opacity: 0;
				}

				&:nth-child(3) {
					bottom: 50%;
					transform: translateY(50%);
				}
			}
		}

		&-wrapper {
			width: 24px;
			height: 14px;
			position: relative;
		}
	}
}

@media (--media-desktop) {
	.hamburger {
		display: none;
	}
}
